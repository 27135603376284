
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.stock_register') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="receive_date_from"
                    >
                      <template v-slot:label>
                        {{ $t('dae_grant_allocation_distribution.receive_date') }} {{ $t('globalTrans.from') }}
                      </template>
                      <b-form-input
                          class="fromDate"
                          id="receive_date_from"
                          v-model="search.receive_date_from"
                          :placeholder="$t('dae_grant_allocation_distribution.receive_date') + ' ' + $t('globalTrans.from')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="receive_date_to"
                    >
                      <template v-slot:label>
                        {{ $t('dae_grant_allocation_distribution.receive_date') }} {{ $t('globalTrans.to') }}
                      </template>
                      <b-form-input
                          class="fromDate"
                          id="receive_date_to"
                          v-model="search.receive_date_to"
                          :placeholder="$t('dae_grant_allocation_distribution.receive_date') + ' ' + $t('globalTrans.to')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          :label="$t('dae_grant_allocation_distribution.allocation_id')"
                          label-for="allocation_id"
                      >
                        <b-form-select
                            plain
                            v-model="search.allocation_id"
                            :options="allocationList"
                            id="allocation_id"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('dae_grant_allocation_distribution.purchase_serial_no')"
                            label-for="purchase_serial_no"
                            >
                            <b-form-input
                                v-model="search.purchase_serial_no"
                                id="purchase_serial_no"
                                >
                            </b-form-input>
                        </b-form-group>
                     </b-col>
                  </b-row>
                  <b-row>
                   <b-col>
                     <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                  </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.stock_register') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(allocation_id_custom)="data">
                                          {{ $n(data.item.allocation_id_custom, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(purchase_serial_no)="data">
                                        {{ $n(data.item.purchase_serial_no, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(purchase_order_date)="data">
                                        {{ data.item.purchase_order_date | dateFormat }}
                                      </template>
                                      <template v-slot:cell(receive_date)="data">
                                        {{ data.item.receive_date | dateFormat }}
                                      </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                          <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </b-button>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="xl" :title="detaisTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { stockRegisterList, stockRegisterStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        receive_date_from: '',
        receive_date_to: '',
        allocation_id: '0',
        purchase_serial_no: ''
      },
      item: ''
    }
  },
  watch: {
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    allocationList: function () {
      const allocationList = this.$store.state.incentiveGrant.commonObj.allocationList.filter(item => item.status === 1)
      return allocationList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: this.$n(parseInt(item.text_bn), { useGrouping: false }) }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    detaisTitle () {
      return this.$t('dae_grant_allocation_distribution.stock_register') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('dae_grant_allocation_distribution.allocation_id'), class: 'text-center' },
        { label: this.$t('dae_grant_allocation_distribution.purchase_serial_no'), class: 'text-center' },
        { label: this.$t('dae_grant_allocation_distribution.purchase_order_date'), class: 'text-center' },
        { label: this.$t('dae_grant_allocation_distribution.receive_date'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'allocation_id_custom' },
          { key: 'purchase_serial_no' },
          { key: 'purchase_order_date' },
          { key: 'receive_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'allocation_id_custom' },
          { key: 'purchase_serial_no' },
          { key: 'purchase_order_date' },
          { key: 'receive_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, stockRegisterStatus, item, 'incentive_grant')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, stockRegisterList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const customItem = {}
        const allocationObj = this.allocationList.find(allocationItem => allocationItem.value === item.allocation_id)
        customItem.allocation_id_custom = parseInt(allocationObj.text)

        return Object.assign({}, item, customItem)
      })
      return listData
    }
  }
}
</script>
