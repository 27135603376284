<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('dae_grant_allocation_distribution.allocation_id') }}</b-th>
                                <b-td>{{ $n(formData.allocation_id_custom, { useGrouping: false }) }}</b-td>
                                <b-th>{{ $t('dae_grant_allocation_distribution.purchase_serial_no') }}</b-th>
                                <b-td>{{ $n(formData.purchase_serial_no, { useGrouping: false }) }}</b-td>
                                 <b-th>{{ $t('dae_grant_allocation_distribution.purchase_order_date') }}</b-th>
                                <b-td>{{ formData.purchase_order_date | dateFormat }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('dae_config.s_name') }}</b-th>
                                <b-td>{{ currentLocale === 'en' ? formData.supplier_name : formData.supplier_name_bn }}</b-td>
                                <b-th>{{ $t('dae_grant_allocation_distribution.supplier_mobile') }}</b-th>
                                <b-td>{{ formData.supplier_mobile }}</b-td>
                                <b-th>{{ $t('dae_grant_allocation_distribution.supplier_address') }}</b-th>
                                <b-td>{{ currentLocale === 'en' ? formData.supplier_address : formData.supplier_address_bn  }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('dae_grant_allocation_distribution.receive_date') }}</b-th>
                                <b-td>{{ formData.receive_date | dateFormat }}</b-td>
                                <b-th>{{ $t('dae_grant_allocation_distribution.comment_en') }}</b-th>
                                <b-td>{{ formData.comments }}</b-td>
                                <b-th>{{ $t('dae_grant_allocation_distribution.comment_bn') }}</b-th>
                                <b-td>{{ formData.comments_bn  }}</b-td>
                              </b-tr>
                          </b-table-simple>
                        </div>
                        <div style="font-size:15px; background-color: #005B5B;">
                          <h5 class="text-white text-center">
                            {{ $t('dae_grant_allocation_distribution.stock_register') }} {{ $t('globalTrans.details') }} {{ $t('globalTrans.list') }}
                          </h5>
                        </div>
                        <b-table-simple striped bordered small class="mt-2">
                          <b-thead>
                            <tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                              <b-th>{{ $t('dae_config.agriculture_material_type') }}</b-th>
                              <b-th>{{ $t('dae_config.agriculture_material_name') }}</b-th>
                              <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.purchase_quantity') }}</b-th>
                              <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.receive_quantity') }}</b-th>
                              <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.available_stock') }}</b-th>
                            </tr>
                          </b-thead>
                          <b-tbody>
                            <template v-if="formData.stock_register_histories.length">
                              <b-tr v-for="(item, index) in formData.stock_register_histories" :key="index">
                                <b-td  class="text-center">{{ index+1 }}</b-td>
                                <b-td class="text-center">{{ currentLocale === 'en' ? item.crop_name : item.crop_name_bn }}</b-td>
                                <b-td class="text-center">{{ currentLocale === 'en' ? item.material_type_name : item.material_type_name_bn }}</b-td>
                                <b-td class="text-center">{{ currentLocale === 'en' ? item.material_name : item.material_name_bn }}</b-td>
                                <b-td class="text-right">{{ $n(item.purchase_quantity) }}</b-td>
                                <b-td class="text-right">{{ $n(item.receive_quantity) }}</b-td>
                                <b-td class="text-right">{{ $n(item.avaialble_stock) }}</b-td>
                              </b-tr>
                            </template>
                            <template v-else>
                              <b-tr>
                                <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    const allocationObj = this.allocationList.find(allocationItem => allocationItem.value === this.formData.allocation_id)
    const supplier = this.supplierList.find(item => item.value === this.formData.supplier_id)
    const paymentMode = this.paymentModeList.find(item => item.value === this.formData.payment_mode)
    const paymentStatus = this.paymentStatusList.find(item => item.value === this.formData.payment_status)
    this.formData.allocation_id_custom = parseInt(allocationObj?.text)
    this.formData.supplier_name = supplier?.text_en
    this.formData.supplier_name_bn = supplier?.text_bn
    this.formData.payment_mode_name = paymentMode?.text_en
    this.formData.payment_mode_name_bn = paymentMode?.text_bn
    this.formData.payment_status_name = paymentStatus?.text_en
    this.formData.payment_status_name_bn = paymentStatus?.text_bn
    this.formData.stock_register_histories = this.formData.stock_register_histories.map(item => {
      const crop = this.cropNameList.find(cropObj => cropObj.value === item.crop_id)
      const agMaterialType = this.agMaterialTypeList.find(agMaterialTypeObj => agMaterialTypeObj.value === item.material_type_id)
      const agMaterial = this.agMaterialList.find(agMaterialObj => agMaterialObj.value === item.material_id)
      const stockRegisterDetail = this.item.stock_register_details.find(stockRegisterDetailObj => {
        if (stockRegisterDetailObj.crop_id === item.crop_id && stockRegisterDetailObj.material_type_id === item.material_type_id && stockRegisterDetailObj.material_id === item.material_id) {
          return stockRegisterDetailObj
        }
      })
      const customItem = {}
      customItem.crop_name = crop?.text_en
      customItem.crop_name_bn = crop?.text_bn
      customItem.material_type_name = agMaterialType?.text_en
      customItem.material_type_name_bn = agMaterialType?.text_bn
      customItem.material_name = agMaterial?.text_en
      customItem.material_name_bn = agMaterial?.text_bn
      customItem.avaialble_stock = typeof stockRegisterDetail !== 'undefined' ? stockRegisterDetail.stock_quantity : 0

      return Object.assign({}, item, customItem)
    })
  },
  data () {
    return {
      formData: {},
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl
    }
  },
   mounted () {
     core.index()
    },
    computed: {
      allocationList: function () {
        const allocationList = this.$store.state.incentiveGrant.commonObj.allocationList.filter(item => item.status === 1)
        return allocationList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: this.$n(parseInt(item.text_bn), { useGrouping: false }) }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      cropNameList: function () {
        return this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      },
      agMaterialTypeList: function () {
        return this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
      },
      agMaterialList: function () {
        return this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
      },
      supplierList: function () {
        return this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
      },
      currentLocale: function () {
        return this.$i18n.locale
      },
      paymentModeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Cash' : 'নগদ', value: 1, text_en: 'Cash', text_bn: 'নগদ' },
          { text: this.currentLocale === 'en' ? 'Credit' : 'ক্রেডিট', value: 2, text_en: 'Credit', text_bn: 'ক্রেডিট' }
        ]
      },
      paymentStatusList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Paid' : 'পরিশোধ', value: 1, text_en: 'Paid', text_bn: 'পরিশোধ' },
          { text: this.currentLocale === 'en' ? 'Unpaid' : 'অবৈতনিক', value: 2, text_en: 'Unpaid', text_bn: 'অবৈতনিক' }
        ]
      }
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
